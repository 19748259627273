@use "@angular/material" as mat;
.dashboard {

  .dashboard-tabs {
    height: 100%;

    > .mat-mdc-tab-header {
      background: white;
      border-bottom: none;
      z-index: 10;
      padding: 0 $padding__page;
      @include lt-sm {  padding: 0 $padding__page__small;  }
      @include mat.elevation(2);;

      .mat-mdc-tab-labels {

        .mat-mdc-tab-label {
          font-weight: 400;
          color: #737373;
          opacity: 1;

          &.mat-mdc-tab-label-active {
            font-weight: 500;
            color: #000;
          }
        }
      }
    }

    .mat-mdc-tab-body-wrapper {
      height: 100%;

      .mat-mdc-tab-body {
        height: 100%;

        .mat-mdc-tab-body-content {
          overflow-x: hidden;
        }
      }
    }
  }
}
