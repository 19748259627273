@import '../var';
.layout.beta {

  /*
  Toolbar
   */

  .toolbar {
    color: $layout-beta__color__toolbar;
    background: $layout-beta__background__toolbar;

    .logo {
      margin-left: 12px;
    }

    .search {
      color: white;

      .mat-mdc-form-field-label {
        color: white;
      }

      .mat-mdc-form-field-underline {
        border-color: white;

        .mat-mdc-form-field-ripple {
          background-color: white;
        }
      }
    }

    .icon-button .icon {
      color: $layout-beta__color__toolbar;
    }
  }

  /*
  Sidenav
   */

  .sidenav {
    //background: $layout-beta__background__sidenav;
   // background-image: linear-gradient(0deg,#3f3f3f,#344634 22%,#40434b);
    background: #2b2762;

    .menu {

      .heading {
        color: $layout-beta__color__sidenav-heading;
      }
    }

  }

  .sidenav-item {
   // color: $layout-beta__color__sidenav-item;
   color: #f0f0f0;

    &:hover {
      color: #FFF;
    }

    &.active {
      color: #FFF;
    }

    .icon, .letter-icon {
      color: #f0f0f0;
      //background: $layout-beta__background__sidenav-item__icon;
    }
  }

  .sidenav-item-container {
    > .sidenav-item {
      &:hover {
       // background: $layout-beta__background-color__sidenav-item__hover;
       background-color: rgba(0,0,0,.3);
      }

      &.active {
        //background: $layout-beta__background-color__sidenav-item__active;
        background:  rgba(0,0,0,.3);;
      }
    }

    > .sub-menu { // Level 1 aka 1st Dropdown
     // background: $layout-beta__background-color__sidenav-item__level1;
     background: #514d8b;
       color: #f0f0f0 !important;

      .sidenav-item-container > .sidenav-item {
        padding-left: $padding__sidenav-item__level1;

        &:hover {
          //background: $layout-beta__background-color__sidenav-item__level1__hover;
          background-color:  rgba(0,0,0,.3);
        }

        &.active {
          background-color:  rgba(0,0,0,.3);
         // background: $layout-beta__background-color__sidenav-item__level1__active;
        }
      }

      > .sub-menu { // Level 2
        background: $layout-beta__background-color__sidenav-item__level2;

        .sidenav-item-container > .sidenav-item {
          padding-left: $padding__sidenav-item__level2;

          &:hover {
            background: $layout-beta__background-color__sidenav-item__level2__hover;
          }

          &.active {
            background: $layout-beta__background-color__sidenav-item__level2__active;
          }
        }

        > .sub-menu { // Level 3
          background: $layout-beta__background-color__sidenav-item__level3;

          .sidenav-item-container > .sidenav-item {
            padding-left: $padding__sidenav-item__level3;

            &:hover {
              background: $layout-beta__background-color__sidenav-item__level3__hover;
            }

            &.active {
              background: $layout-beta__background-color__sidenav-item__level3__active;
            }
          }

          > .sub-menu { // Level 4
            background: $layout-beta__background-color__sidenav-item__level4;

            .sidenav-item-container > .sidenav-item {
              padding-left: $padding__sidenav-item__level4;

              &:hover {
                background: $layout-beta__background-color__sidenav-item__level4__hover;
              }

              &.active {
                background: $layout-beta__background-color__sidenav-item__level4__active;
              }
            }

            > .sub-menu { // Level 5
              background: $layout-beta__background-color__sidenav-item__level5;

              .sidenav-item-container > .sidenav-item {
                padding-left: $padding__sidenav-item__level5;

                &:hover {
                  background: $layout-beta__background-color__sidenav-item__level5__hover;
                }

                &.active {
                  background: $layout-beta__background-color__sidenav-item__level5__active;
                }
              }

              .sub-menu { // Infinite Levels after
                background: $layout-beta__background-color__sidenav-item__level6;

                .sidenav-item-container > .sidenav-item {
                  padding-left: $padding__sidenav-item__level6;

                  &:hover {
                    background: $layout-beta__background-color__sidenav-item__level6__hover;
                  }

                  &.active {
                    background: $layout-beta__background-color__sidenav-item__level6__active;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .sidenav-item-container .sub-menu {
    position: relative;
    padding: 0;
    overflow: hidden;

    &:before, &:after {
      content: " ";
      left: 0;
      position: absolute;
      width: 100%;
      height: 2px;
      z-index: 3;
    }

    &:before {
      background: linear-gradient(180deg, rgba(0,0,0,.1) 0, transparent);
      top: 0;
    }

    &:after {
      background: linear-gradient(-180deg, rgba(0,0,0,.1) 0, transparent);
      bottom: 0;
    }
  }
}
