$height__chat-header: 150px;
$height__chat-toolbar: 64px;
$height__chat-container: 700px;

.page-chat {
  height: 100%;
  display: flex;
  flex-direction: column;
  @include responsivePadding(right);
  @include responsivePadding(bottom);
  @include responsivePadding(left);

  .chat-toolbar {
    width: 100%;
    border-bottom: 1px solid #EEE;
    min-height: 64px;
    background: #f5f5f5;
    padding: 0 16px;

    .current-contact {
      .avatar {
        margin: 0 14px 0 10px;
      }

      .name {
        font-size: 18px;
        font-weight: 500;
      }

      .status {
        font-size: 14px;
        color: #888;
      }
    }
  }

  .chat-contacts {
    padding-top: 0;

    .chat-contact {
      position: relative;
      cursor: pointer;
      transition: background .1s linear;

      &:hover {
        background: #EEE;
      }

      .name {
      }

      .last-message {
        color: #888;
      }
    }
  }

  .chat-inner-container {
    height: 100%;

    .chat-content {
      position: relative;
      background: url('/assets/img/demo/backgrounds/arch-pattern.png') repeat;

      .messages-container {
        padding: 24px;

        .chat-message {
          padding: 24px 0;

          &:last-child {
            padding: 24px 0 0;
          }

          .message {
            padding: 8px;
            position: relative;
            box-shadow: 0 0px 0px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
          }

          .partner {
            .message {
              background: white;
              margin-left: 12px;

              &:after {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0px 10px 10px 0;
                border-color: transparent #fff transparent transparent;
                top: 0;
                left: -10px;
              }
            }
          }

          .me {
            .message {
              background: #E1FFC7;
              margin-right: 12px;

              &:after {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0px 0 10px 10px;
                border-color: transparent transparent transparent #e1ffc7;
                top: 0;
                right: -10px;
              }
            }
          }
        }
      }

      .chat-respond {
        background: white;
        width: 100%;
        padding: 6px 24px;
        border-top: 1px solid #EEE;

        textarea {
          resize: none;
        }

        button {
          margin-left: 24px;
        }
      }
    }
  }

  mat-drawer.mat-drawer-opened.mat-elevation-z2, mat-drawer.mat-drawer-opening.mat-elevation-z2 {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  }
}


