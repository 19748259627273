@use "@angular/material" as mat;
@use 'sass:map';
@import './var';


html, body {
  min-height: 100%;
  height: 100%;
  line-height: normal;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;
  color: #111;
  zoom:100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div {
  box-sizing: border-box;
}

.scroll-content {
  min-height: 100%;
  position: relative;
}

p {
  line-height: 1.5;
}

.divider {
  width: 100%;
  height: 1px;
  background: #E0E0E0;

  &.reverse {
    width: inherit;
    @include responsivePadding(left);
    @include responsivePadding(right);
    @include responsivePadding(left, true);
    @include responsivePadding(right, true);
  }
}

.mat-drawer-content {
  overflow-x: hidden;
}

code.hljs {
  white-space: pre;
  padding: 16px;
}

/*h3 {
  color: $color__text-darker;
  padding: 0 0 8px;
  font-weight: normal;
  font-size: 18px;
  border-bottom: 1px solid $color__border;
}*/

@keyframes pageLoad {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.page-load-animation {
  opacity: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  animation: pageLoad ease-in-out 1;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  animation-delay: 0.3s;
  background: #FFF;
  z-index: 9999;
  pointer-events: none;
}

.status-circle {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;

  &.primary {
    background: mat.get-color-from-palette(map.get($theme, primary));
  }

  &.accent {
    background: mat.get-color-from-palette(map.get($theme, accent));
  }

  &.warn {
    background: mat.get-color-from-palette(map.get($theme, warn));
  }

  &.orange {
    background: var($orange);
  }

  &.success {
    background: var($green);
  }

  &.donut {
    border: 3px solid;
    background: transparent;

    &.primary {
      border-color: mat.get-color-from-palette(map.get($theme, primary));
    }

    &.accent {
      border-color: mat.get-color-from-palette(map.get($theme, accent));
    }

    &.warn {
      border-color: mat.get-color-from-palette(map.get($theme, warn));
    }

    &.orange {
      border-color: var($orange);
    }

    &.success {
      background: var($green);
    }
  }
}


@keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}

$height__buy-now-toolbar: 60px;

.buy-now-toolbar {
  height: $height__buy-now-toolbar;
  position: relative;
  z-index: 999;

  & + .layout-container {
    height: calc(100% - #{$height__buy-now-toolbar}) !important;
  }

  .rainbow {
    height: 100%;
    width: 100%;
    left:0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
    background-size: 1800% 1800%;
    animation: rainbow 18s ease infinite;
    padding: 0 24px;

    .name {
      color: white;
      font-weight: 500;
      text-shadow: 0 0 20px rgba(150, 150, 150, 1);
    }

    .action {
      margin-left: 24px;

      .icon {
        font-size: 18px;
        height: 18px;
        width: 18px;
        line-height: 18px;
        margin-right: 6px;
      }
    }

    .close {
      color: white;
      text-shadow: 0 0 10px rgba(0, 0, 0, 1);
    }
  }

}
