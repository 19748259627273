.semi-bold {
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
