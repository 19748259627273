.layout.boxed {

  .container {
    max-width: 1280px;
    margin: 0 auto;
  }

  .dashboard .dashboard-tabs .mat-mdc-tab-label-container {
    max-width: 1280px;
    margin: 0 auto;
  }
}

.container.always {
  max-width: 1280px;
  margin: 0 auto;
}
