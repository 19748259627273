.quickpanel-container, .sidenav-container {
  overflow-y: hidden !important;
}

.quickpanel .mat-mdc-tab-body-wrapper {
  height: 100%;
}

.quickpanel .mat-mdc-tab-labels {
  .mat-mdc-tab-label {
    flex: 1;
  }
}
