/*
================================================================================
*****************************  Common Styles  **********************************
================================================================================
*/
@charset "UTF-8";
/*!
 * Custom CSS v1.0.0
 */
:root {
    --custom-blue: #274298;
    --custom-indigo: #6610f2;
    --custom-purple: #6f42c1;
    --custom-pink: #d63384;
    --custom-red: #d92550;
    --custom-orange: #fd7e14;
    --custom-yellow: #F8951D;
    --custom-green: #3ac47d;
    --custom-teal: #20c997;
    --custom-cyan: #29A9E9;
    --custom-black: #000;
    --custom-white: #fff;
    --custom-gray: #6c757d;
    --custom-gray-dark: #343a40;
    --custom-gray-100: #f8f9fa;
    --custom-gray-200: #e9ecef;
    --custom-gray-300: #dee2e6;
    --custom-gray-400: #ced4da;
    --custom-gray-500: #adb5bd;
    --custom-gray-600: #6c757d;
    --custom-gray-700: #495057;
    --custom-gray-800: #343a40;
    --custom-gray-900: #2A2762;
    --custom-primary: #274298;
    --custom-secondary: #6c757d;
    --custom-success: #3ac47d;
    --custom-info: #29A9E9;
    --custom-warning: #F8951D;
    --custom-danger: #d92550;
    --custom-light: #f8f9fa;
    --custom-dark: #2A2762;
    --custom-primary-rgb: 13, 110, 253;
    --custom-secondary-rgb: 108, 117, 125;
    --custom-success-rgb: 25, 135, 84;
    --custom-info-rgb: 13, 202, 240;
    --custom-warning-rgb: 255, 193, 7;
    --custom-danger-rgb: 220, 53, 69;
    --custom-light-rgb: 248, 249, 250;
    --custom-dark-rgb: 33, 37, 41;
    --custom-primary-text-emphasis: #052c65;
    --custom-secondary-text-emphasis: #2b2f32;
    --custom-success-text-emphasis: #0a3622;
    --custom-info-text-emphasis: #055160;
    --custom-warning-text-emphasis: #664d03;
    --custom-danger-text-emphasis: #58151c;
    --custom-light-text-emphasis: #495057;
    --custom-dark-text-emphasis: #495057;
    --custom-primary-bg-subtle: #cfe2ff;
    --custom-secondary-bg-subtle: #e2e3e5;
    --custom-success-bg-subtle: #d1e7dd;
    --custom-info-bg-subtle: #cff4fc;
    --custom-warning-bg-subtle: #fff3cd;
    --custom-danger-bg-subtle: #f8d7da;
    --custom-light-bg-subtle: #fcfcfd;
    --custom-dark-bg-subtle: #ced4da;
    --custom-primary-border-subtle: #9ec5fe;
    --custom-secondary-border-subtle: #c4c8cb;
    --custom-success-border-subtle: #a3cfbb;
    --custom-info-border-subtle: #9eeaf9;
    --custom-warning-border-subtle: #ffe69c;
    --custom-danger-border-subtle: #f1aeb5;
    --custom-light-border-subtle: #e9ecef;
    --custom-dark-border-subtle: #adb5bd;
    --custom-white-rgb: 255, 255, 255;
    --custom-black-rgb: 0, 0, 0;
    --custom-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --custom-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --custom-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --custom-body-font-family: var(--custom-font-sans-serif);
    --custom-body-font-size: 1rem;
    --custom-body-font-weight: 400;
    --custom-body-line-height: 1.5;
    --custom-body-color: #495057;
    --custom-body-color-rgb: 33, 37, 41;
    --custom-body-bg: #f1f4f6;
    --custom-body-bg-rgb: 255, 255, 255;
    --custom-emphasis-color: #000;
    --custom-emphasis-color-rgb: 0, 0, 0;
    --custom-secondary-color: rgba(33, 37, 41, 0.75);
    --custom-secondary-color-rgb: 33, 37, 41;
    --custom-secondary-bg: #e9ecef;
    --custom-secondary-bg-rgb: 233, 236, 239;
    --custom-tertiary-color: rgba(33, 37, 41, 0.5);
    --custom-tertiary-color-rgb: 33, 37, 41;
    --custom-tertiary-bg: #f8f9fa;
    --custom-tertiary-bg-rgb: 248, 249, 250;
    --custom-link-color: var(--custom-primary);
    --custom-link-color-rgb: 13, 110, 253;
    --custom-link-decoration: underline;
    --custom-link-hover-color: #0a58ca;
    --custom-link-hover-color-rgb: 10, 88, 202;
    --custom-code-color: #d63384;
    --custom-highlight-bg: #fff3cd;
    --custom-border-width: 1px;
    --custom-border-style: solid;
    --custom-border-color: #dee2e6;
    --custom-border-color-translucent: rgba(0, 0, 0, 0.175);
    --custom-border-radius: 0.375rem;
    --custom-border-radius-sm: 0.25rem;
    --custom-border-radius-lg: 0.5rem;
    --custom-border-radius-xl: 1rem;
    --custom-border-radius-xxl: 2rem;
    --custom-border-radius-2xl: var(--custom-border-radius-xxl);
    --custom-border-radius-pill: 50rem;
    --custom-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --custom-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --custom-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    --custom-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --custom-box-shadow-primary: 0 .46875rem 2.1875rem rgba(63, 106, 216, .03), 0 .9375rem 1.40625rem rgba(63, 106, 216, .03), 0 .25rem .53125rem rgba(63, 106, 216, .05), 0 .125rem .1875rem rgba(63, 106, 216, .03);
    --custom-box-shadow-secondary: 0 .46875rem 2.1875rem rgba(108, 117, 125, .03), 0 .9375rem 1.40625rem rgba(108, 117, 125, .03), 0 .25rem .53125rem rgba(108, 117, 125, .05), 0 .125rem .1875rem rgba(108, 117, 125, .03);
    --custom-box-shadow-success: 0 .46875rem 2.1875rem rgba(58, 196, 125, .03), 0 .9375rem 1.40625rem rgba(58, 196, 125, .03), 0 .25rem .53125rem rgba(58, 196, 125, .05), 0 .125rem .1875rem rgba(58, 196, 125, .03);
    --custom-box-shadow-info: 0 .46875rem 2.1875rem rgba(22, 170, 255, .03), 0 .9375rem 1.40625rem rgba(22, 170, 255, .03), 0 .25rem .53125rem rgba(22, 170, 255, .05), 0 .125rem .1875rem rgba(22, 170, 255, .03);
    --custom-box-shadow-warning: 0 .46875rem 2.1875rem rgba(247, 185, 36, .03), 0 .9375rem 1.40625rem rgba(247, 185, 36, .03), 0 .25rem .53125rem rgba(247, 185, 36, .05), 0 .125rem .1875rem rgba(247, 185, 36, .03);
    --custom-box-shadow-danger: 0 .46875rem 2.1875rem rgba(217, 37, 80, .03), 0 .9375rem 1.40625rem rgba(217, 37, 80, .03), 0 .25rem .53125rem rgba(217, 37, 80, .05), 0 .125rem .1875rem rgba(217, 37, 80, .03);
    --custom-box-shadow-light: 0 .46875rem 2.1875rem rgba(238, 238, 238, .03), 0 .9375rem 1.40625rem rgba(238, 238, 238, .03), 0 .25rem .53125rem rgba(238, 238, 238, .05), 0 .125rem .1875rem rgba(238, 238, 238, .03);
    --custom-box-shadow-dark: 0 .46875rem 2.1875rem rgba(52, 58, 64, .03), 0 .9375rem 1.40625rem rgba(52, 58, 64, .03), 0 .25rem .53125rem rgba(52, 58, 64, .05), 0 .125rem .1875rem rgba(52, 58, 64, .03);
    --custom-box-shadow-dark: 0 .46875rem 2.1875rem rgba(52, 58, 64, .03), 0 .9375rem 1.40625rem rgba(52, 58, 64, .03), 0 .25rem .53125rem rgba(52, 58, 64, .05), 0 .125rem .1875rem rgba(52, 58, 64, .03);
    --custom-box-shadow-focus: 0 .46875rem 2.1875rem rgba(68, 64, 84, .03), 0 .9375rem 1.40625rem rgba(68, 64, 84, .03), 0 .25rem .53125rem rgba(68, 64, 84, .05), 0 .125rem .1875rem rgba(68, 64, 84, .03);
    --custom-box-shadow-alternate: 0 .46875rem 2.1875rem rgba(121, 76, 138, .03), 0 .9375rem 1.40625rem rgba(121, 76, 138, .03), 0 .25rem .53125rem rgba(121, 76, 138, .05), 0 .125rem .1875rem rgba(121, 76, 138, .03);
    --custom-focus-ring-width: 0.25rem;
    --custom-focus-ring-opacity: 0.25;
    --custom-focus-ring-color: rgba(13, 110, 253, 0.25);
    --custom-form-valid-color: var(--custom-success);
    --custom-form-valid-border-color: var(--custom-success);
    --custom-form-invalid-color: var(--custom-danger);
    --custom-form-invalid-border-color: var(--custom-danger);
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}
html,
body {
    min-height: 100vh;
    height: auto;
}
body {
    font-family: var(--custom-body-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    background-color: var(--custom-body-bg);
    color: var(--custom-body-color);
    margin: 0;
}
.custom-title {
    font-size: 18px !important;
    font-weight: 400 !important;
    margin: 0 0 15px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.custom-subtitle {
    font-size: 14px !important;
    font-weight: 300 !important;
    margin: 0 0 15px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.custom-text {
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 15px;
    white-space: normal;
}
.custom-margin-top-base {
    margin-top: 15px !important;
}
.custom-margin-right-base {
    margin-right: 15px !important;
}
.custom-margin-bottom-base {
    margin-bottom: 15px !important;
}
.custom-margin-left-base {
    margin-left: 15px !important;
}
.custom-margin-0 {
    margin: 0 !important;
}
.custom-margin-top-0 {
    margin-top: 0 !important;
}
.custom-margin-right-0 {
    margin-right: 0 !important;
}
.custom-margin-bottom-0 {
    margin-bottom: 0 !important;
}
.custom-margin-left-0 {
    margin-left: 0 !important;
}
.custom-padding-top-base {
    padding-top: 15px !important;
}
.custom-padding-right-base {
    padding-right: 15px !important;
}
.custom-padding-bottom-base {
    padding-bottom: 15px !important;
}
.custom-padding-left-base {
    padding-left: 15px !important;
}
.custom-padding-0 {
    padding: 0 !important;
}
.custom-padding-top-0 {
    padding-top: 0 !important;
}
.custom-padding-right-0 {
    padding-right: 0 !important;
}
.custom-padding-bottom-0 {
    padding-bottom: 0 !important;
}
.custom-padding-left-0 {
    padding-left: 0 !important;
}
.custom-text-primary {
    color: var(--custom-primary) !important;
}
.custom-text-secondary {
    color: var(--custom-secondary) !important;
}
.custom-text-success {
    color: var(--custom-success) !important;
}
.custom-text-info {
    color: var(--custom-info) !important;
}
.custom-text-warning {
    color: var(--custom-warning) !important;
}
.custom-text-danger {
    color: var(--custom-danger) !important;
}
.custom-text-light {
    color: var(--custom-light) !important;
}
.custom-text-dark {
    color: var(--custom-dark) !important;
}
.custom-text-white {
    color: var(--custom-white) !important;
}
.custom-text-black {
    color: var(--custom-black) !important;
}
.custom-bg-primary {
    background-color:  #274298 !important;
    color: #fff !important;
}
.custom-bg-secondary {
    background-color: var(--custom-secondary) !important;
}
.custom-bg-success {
    background-color: var(--custom-success) !important;
}
.custom-bg-info {
    /* background-color: var(--custom-info) !important; */
}
.custom-bg-warning {
    background-color: var(--custom-warning) !important;
}
.custom-bg-danger {
    background-color: var(--custom-danger) !important;
}
.custom-bg-light {
    background-color: var(--custom-light) !important;
}
.custom-bg-dark {
    background-color: var(--custom-dark) !important;
}
.custom-bg-white {
    background-color: var(--custom-white) !important;
}
.custom-border-bottom-primary {
    border-bottom: 5px solid var(--custom-primary) !important;
}
.custom-border-bottom-secondary {
    border-bottom: 5px solid var(--custom-secondary) !important;
}
.custom-border-bottom-success {
    border-bottom: 5px solid var(--custom-success) !important;
}
.custom-border-bottom-info {
    border-bottom: 5px solid var(--custom-info) !important;
}
.custom-border-bottom-warning {
    border-bottom: 5px solid var(--custom-warning) !important;
}
.custom-border-bottom-danger {
    border-bottom: 5px solid var(--custom-danger) !important;
}
.custom-border-bottom-light {
    border-bottom: 5px solid var(--custom-warning) !important;
}
.custom-border-bottom-dark {
    border-bottom: 5px solid var(--custom-dark) !important;
}
.custom-box-shadow-primary {
    box-shadow: var(--custom-box-shadow-primary) !important;
}
.custom-box-shadow-secondary {
    box-shadow: var(--custom-box-shadow-secondary) !important;
}
.custom-box-shadow-success {
    box-shadow: var(--custom-box-shadow-success) !important;
}
.custom-box-shadow-info {
    box-shadow: var(--custom-box-shadow-info) !important;
}
.custom-box-shadow-warning {
    box-shadow: var(--custom-box-shadow-warning) !important;
}
.custom-box-shadow-danger {
    box-shadow: var(--custom-box-shadow-danger) !important;
}
.custom-box-shadow-light {
    box-shadow: var(--custom-box-shadow-light) !important;
}
.custom-box-shadow-dark {
    box-shadow: var(--custom-box-shadow-dark) !important;
}
.custom-box-shadow-focus {
    box-shadow: var(--custom-box-shadow-focus) !important;
}
.custom-box-shadow-alternate {
    box-shadow: var(--custom-box-shadow-alternate) !important;
}
/*--  Custom Row  --*/
.custom-row {
    display: flex;
}
.custom-circle-progress {
    position: relative;
}
.custom-circle-progress small {
    position: absolute;
    height: 100%;
    width: 100%;
    font-weight: 700;
    left: 0;
    top: 0;
    vertical-align: middle;
    text-align: center;
    display: flex;
    align-items: center;
    align-content: center;
}
.custom-circle-progress small span {
    margin: 0 auto;
}
.custom-circle-progress canvas {
    display: block;
}
.custom-icon {
    font-size: 24px;
}
.custom-btn-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.custom-btn-wrapper .custom-btn {
    margin: 0 15px 0 0;
    white-space: nowrap;
}
.custom-btn-wrapper .custom-title {
    margin: 0 !important;
}
.custom-btn.mat-primary {
    background-color: var(--custom-primary) !important;
}
.custom-scroll {
    overflow: auto;
}
.custom-horizontal-scroll {
    overflow-x: auto;
    overflow-y: hidden;
}
.custom-vertical-scroll {
    overflow-y: auto;
    overflow-x: hidden;
}

/*--  Custom Progressbar Styles  --*/
.custom-progressbar-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.custom-progressbar-wrapper .custom-progressbar {
    width: calc(100% - 60px);
}
.custom-progressbar-wrapper .custom-progressbar .mdc-linear-progress__bar-inner {
    background-color: var(--custom-primary);
    border-color: var(--custom-primary);
}
.custom-progressbar-wrapper .custom-progressbar-label {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
}
.custom-progressbar-wrapper .custom-progressbar-value {
    width: 50px;
    padding: 0 0 0 10px;
    font-size: 14px;
    font-weight: normal;
}
.custom-card {
    flex-direction: column;
    width: 100%;
}
.custom-card .custom-card-header {
    width: 100%;
    align-items: center;
    padding: 4px 10px;
    border-bottom: 1px solid rgba(26, 54, 126, .125);
   
}
.custom-card .custom-card-header .mat-mdc-card-header-text {
    width: 100%;
}
.custom-card .custom-card-header .custom-card-title {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
}
.custom-card .custom-card-header .custom-card-title .custom-card-avatar {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border-radius: 50%;
}
.custom-card .custom-card-header .custom-card-title .custom-card-avatar .custom-card-avatar-logo {
    max-width: 100%;
    display: block;
    height: auto;
    margin: 0 auto;
}
.custom-card .custom-card-content {
    padding: 15px;
}
.custom-list {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding: 0 !important;
}
.custom-list .custom-list-item {
    position: relative;
    height: 100% !important;
    margin: 0 0 30px;
    padding: 0 !important;
}
.custom-list .custom-list-item .mdc-list-item__content {
    position: relative;
    height: 100%;
}

/*--  Custom Form Styles  --*/
.custom-form {
    position: relative;
}

.custom-form .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    color: var(--custom-primary);
    top: 20px;
}
.custom-form .mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mdc-floating-label--float-above {
    color: var(--custom-primary);
    top: 25px;
}
.custom-form .custom-form-select {
    height: 100%;
    font-size: 14px;
    line-height: 18px;
}
.custom-form .custom-form-select .mat-mdc-select-arrow-wrapper {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 25px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--custom-primary);
}
.custom-form .custom-form-select[aria-expanded="false"] .mat-mdc-select-arrow-wrapper:before {
    content: "\f107";
}
.custom-form .custom-form-select[aria-expanded="true"] .mat-mdc-select-arrow-wrapper:before {
    content: "\f106";
}
.custom-form .custom-form-select .mat-mdc-select-arrow-wrapper svg {
    display: none;
}
.custom-form .custom-form-select .custom-form-select-trigger {
    height: 100%;
}
.custom-form .custom-form-select .custom-form-select-option {
    color: var(--custom-primary);
}
.custom-form .mat-mdc-text-field-wrapper {
    width: 100%;
    border-radius: 5px;
    height: 45px;
    background-color: var(--custom-white);
    border: 1px solid var(--custom-white);
}
.custom-form .mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay {
    background-color: var(--custom-white);
}
.custom-form .mat-mdc-text-field-wrapper.mdc-text-field--focused {
    border-radius: 5px 5px 0 0;
}
.custom-form .mdc-line-ripple {
    opacity: 0;
}
.custom-form .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 16px;
    padding-bottom: 8px;
}
.custom-form .mat-mdc-form-field-subscript-wrapper {
    height: 0;
}
.mdc-menu-surface.mat-mdc-select-panel {
    padding: 0 !important;
}
.mat-mdc-option {
    background-color: var(--custom-white) !important;
    font-size: 14px !important;
    min-height: 45px !important;
}
.mat-mdc-option:hover,
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) {
    background-color: var(--custom-primary) !important;
    color: var(--custom-white) !important;
}
.mat-primary .mat-mdc-option:hover .mdc-list-item__primary-text,
.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: var(--custom-white) !important;
}

/*--  Custom Menu Styles  --*/
.custom-menu .mat-mdc-menu-content {
    padding: 0;
}
.custom-menu .custom-menu-item .custom-icon {
    vertical-align: middle;
    margin: 0 15px 0 0;
    color: var(--custom-primary);
}
/*--  Custom Swiper Styles  --*/
/* .custom-swiper-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
    padding: 0 0 10px;
}
.custom-swiper-wrapper .swiper-slide {
   
    margin: 0 15px !important;
} */
/* .swiper-pagination {
    bottom: -5px !important;
}
.swiper-container {
    width: calc(100% - 90px) !important;
    margin: 0px 45px;
    padding: 0 15px;
    overflow: hidden;
}
.swiper-slide-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #fff;
    height: 100%;
    max-width: 600px;
    margin: auto;
    border-radius: 20px;
} */
.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
}
/* .swiper-button-prev,
.swiper-button-next {
    transform: translateY(-50%);
}
.swiper-button-prev:after,
.swiper-button-next:after {
    color: var(--custom-primary);
    font-weight: bolder;
    font-size: 35px;
}
.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: var(--custom-white);
    opacity: 1;
    border: 1px solid var(--custom-primary);
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--custom-primary);
    opacity: 1;
    border: 1px solid var(--custom-primary);
} */
/*
================================================================================
***************************  Common Media Styles  ******************************
================================================================================
*/
/* @media (min-width: 1200px) {}
@media (min-width: 992px) and (max-width: 1199px) {
    .custom-swiper-wrapper .swiper-slide {
        width: calc(33.33% - 30px) !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .custom-swiper-wrapper .swiper-slide {
        width: calc(50% - 30px) !important;
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    .custom-swiper-wrapper .swiper-slide {
        width: calc(100% - 30px) !important;
    }
}
@media (min-width: 320px) and (max-width: 480px) {} */
