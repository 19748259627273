

@use "@angular/material" as mat;
@use 'sass:map';
@import './var';

.badge {
  padding: 3px 8px;
  border-radius: 12px;
  line-height: normal;

  &.primary {
    background: mat.get-color-from-palette(map.get($theme, primary));
    color: #fff;
  }

  &.accent {
    background: mat.get-color-from-palette(map.get($theme, accent));
    color: #fff;
  }

  &.warn {
    background: mat.get-color-from-palette(map.get($theme, warn));
    color: #fff;
  }
}
