.profile {


  .profile-overview {

    .chips {

      .mat-chip {
        margin: 8px 8px 0 0 !important;
      }
    }
  }
}
