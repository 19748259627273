
@use "@angular/material" as mat;
@use 'sass:map';
@import './var';

.clean-tabs {

  .mat-mdc-tab-labels {

    .mat-mdc-tab-label {

      &.mat-mdc-tab-label-active {
        color: mat.get-color-from-palette(map.get($theme, primary));
        opacity: 1;
      }
    }
  }
}
