    /* You can add global styles to this file, and also import other style files */

    @import "~normalize.css/normalize.css";
    $fa-font-path: "../node_modules/font-awesome/fonts";
    @import "~font-awesome/css/font-awesome.css";
    @import "~highlight.js/styles/androidstudio.css";
    @import "../node_modules/angular-calendar/css/angular-calendar.css";
    @import "~@ng-select/ng-select/themes/material.theme.css";
    @import "styles/var";
    @import "styles/misc";
    @import "styles/card";
    @import "styles/boxed";
    @import "styles/tabs";
    @import "styles/badges";
    @import "styles/helpers";
    @import "styles/tables";
    @import "styles/tour";
    @import "styles/elevation";
    @import "styles/components/sidenav";
    @import "styles/components/quickpanel";
    @import "styles/components/toolbar";
    @import "styles/components/dashboard";
    @import "styles/settings/profile";
    @import "styles/settings/form-wizard";
    @import "styles/settings/chat";
    @import "styles/layouts/layout-alpha";
    @import "styles/layouts/layout-beta";
    @import "styles/layouts/layout-gamma";
    @import "styles/settings/components";
    @import "node_modules/@swimlane/ngx-datatable/index.css";
    @import "node_modules/@swimlane/ngx-datatable/themes/dark.css";
    @import "node_modules/@swimlane/ngx-datatable/themes/bootstrap.css";
    @import "node_modules/@swimlane/ngx-datatable/themes/material.css";
    @import "node_modules/@swimlane/ngx-datatable/assets/icons.css";
    @import 'styles/custom.css';

    .mat-icon {
        height: 18px;
        width: 18px;
    }

    .material-icons {
        font-size: 18px !important;
    }

    .actions {
        .mat-mdc-mini-fab {
            .mat-icon {
                position: relative;
                top: 3px !important;
            }
        }

        .reset-button {
            text-align: center;
            background: #41a541 !important;
            color: #fff !important;
            margin-left: 8px;
            padding: 0px 10px;

            .mat-icon {
                position: relative;
                margin-right: 5px;
            }
        }

        .filter-button {
            text-align: center;
            background: #1976d2 !important;
            color: #ffffff !important;
            margin-left: 8px;
            padding: 0px 10px;

            .mat-icon {
                position: relative;
                margin-right: 5px;
            }
        }
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background: none !important;
    }

    .border-blue {
        border-bottom: 3px solid $blue !important;
        // border-color: $blue !important;
    }

    .add-property-items-link {
        color: #41a541;
        text-decoration: none;
        opacity: 1;
        cursor: pointer;

        .mat-icon {
            position: relative;
            top: 4px;
        }
    }

    .border-orange {
        // border-color: $orange !important;
        border-bottom: 3px solid $orange !important;
    }

    .border-violet {
        // border-color: $violet !important;
        border-bottom: 3px solid $violet !important;
    }

    .border-pink {
        // border-color: $pink !important;
        border-bottom: 3px solid $pink !important;
    }

    .mat-mini-fab {
        width: 34px !important;
        height: 34px !important;
    }

    .top-2 {
        position: relative;
        top: 2px;
    }

    .top-6 {
        position: relative;
        top: 6px;
    }


    .mat-mdc-button.mat-button-wrapper>*,
    .mat-fab .mat-button-wrapper>*,
    .mat-icon-button .mat-button-wrapper>*,
    .mat-mini-fab .mat-button-wrapper>*,
    .mat-raised-button .mat-button-wrapper>* {
        vertical-align: baseline !important;
    }



    .select-project.mat-mdc-form-field {
        display: block;
        margin-top: 10px;

        .mat-mdc-form-field-wrapper {
            padding-bottom: 5px;

            .mat-mdc-form-field-flex {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .mat-mdc-form-field-underline {
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.15);
        }
    }

    .select-employee {
        overflow: auto;
        padding-top: 0 !important;

        // max-height: calc(100% - 60px);
        .mat-list-option[aria-selected="true"] {
            background: #ecf6f4 !important;
            color: #41a541 !important;
        }

        .mat-list-item-content {
            padding-left: 56px !important;

            .mat-list-text {
                padding-right: 0 !important;
            }
        }

        .employee-avatar {
            width: 30px;
            height: 30px;
            float: left;
            border-radius: 50%;
            background-color: #ddd;
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: 16px;
            margin-top: -15px !important;
        }
    }

    .location-header {
        position: relative;

        .mat-mdc-card-avatar {
            background-size: cover;
        }

        .calender-location {
            position: absolute;
            right: 16px;
            top: -5px;

            .mat-focus-indicator {
                vertical-align: middle !important;
            }
        }
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
        background-color: #88ca88 !important;
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
        background-color: #41a541 !important;
    }

    .add-property {
        .mat-radio-button~.mat-radio-button {
            margin-left: 16px;
        }

        .mat-radio-label {
            font-weight: 600;
        }

        .mat-radio-checked .mat-radio-inner-circle {
            background-color: #41a541;
        }

        .mat-radio-checked .mat-radio-outer-circle {
            border-color: #41a541 !important;
        }

        .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
            background-color: #41a541;
        }

        .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
            background-color: #88ca88;
        }

        .mat-checkbox-checked.mat-accent .mat-checkbox-background {
            background-color: #41a541;
        }

        .mat-checkbox-label {
            position: relative;
            display: inline-block;
            min-height: 24px;
            padding-left: 5px;
            font-family: proxima-nova, Avenir, sans-serif;
            font-size: 14px;
            line-height: 24px;
            text-align: left;
            text-transform: none;
            letter-spacing: 0;
            cursor: pointer;
        }
    }

    .g-sidenav {
        .mat-drawer-inner-container {
            overflow-x: hidden !important;
        }
    }

    .search-wrapper {
        .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
            color: #f0f0f0 !important;
        }

        .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
            background-color: #f0f0f0 !important;
        }
    }

    .all-in-one-table {
        padding: 24px 10% !important;
    }

    pdf-viewer {
        display: block;
    }

    .view-card-content {
        .mat-mdc-tab-header {
            border-bottom: 0px !important;
            margin-bottom: 5px;
        }

        .mat-mdc-tab-labels {
            background-color: #F0F0F7 !important;
        }

        .mat-mdc-tab-label-active {
            color: #322A7D !important;
            // background: #fff !important;
        }

        .mat-mdc-tab-label {
            color: #BBC5D5;
            opacity: 1 !important;
        }

        .mat-ink-bar {
            height: 2px;
            background-color: #FFA101 !important;
        }

        .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
            background: #fff;
            ;
        }

        .mat-mdc-tab-header-pagination-chevron {
            border-color: #808495;
        }

        // .mat-mdc-tab-header-pagination-disabled .mat-mdc-tab-header-pagination-chevron {
        //    // border-color: #fff;
        // }
    }

    .p-l-10 {
        padding-left: 10px;
    }

    .p-t-0 {
        padding-top: 0px !important;
    }

    .no-padding {
        padding: 0px !important;
    }

    .no-margin {
        margin: 0px;
    }

    .pos-rel {
        position: relative !important;

        .all-in-one-table {
            padding: 0px !important;

            .list {
                box-shadow: none !important;
            }
        }
    }

    .no_box_shadow {
        box-shadow: none !important;
    }

    .expense_button {
        border-bottom: 1px solid silver !important;
        padding: 10px !important;

        .mdc-button__label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    .expense_menu {

        height: 250px;
        max-width: 500px;
        width: 400px;

    }


    .employee-table {
        .mat-mdc-header-row {
            width: fit-content !important;
        }

        .mat-mdc-row {
            width: fit-content !important;
        }
    }

    .mat-mdc-table {
        width: 100%;
        overflow-x: auto;
    }

    .full-width {
        width: 100%;
    }

    .highlighted {
        background: #e4f060;
        border-radius: 10px;
    }

    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: #1976d2 !important;
    }

    .cdk-overlay-pane.confirmation-dialog {
        position: relative !important;
    }

    //   .confirmation-dialog  {
    //     .close.mat-mdc-button{
    //             position: absolute;
    //             right: 5px;
    //             padding: 5px;
    //             margin: 0px;
    //             line-height: 0px;
    //             min-width: auto;
    //             color: #fff;
    //       }
    //   }
    .mat-mdc-paginator {
        background: none !important
    }

    .mat-mdc-paginator-container {
        margin-bottom: 10px;
        min-height: auto !important;
    }

    .mat-mdc-paginator-page-size,
    .mat-mdc-paginator-range-label {
        font-size: 14px;
        background: none;
        color: black;
    }

    .mat-mdc-paginator-navigation-next,
    .mat-mdc-paginator-navigation-previous,
    .mat-mdc-paginator-navigation-last,
    .mat-mdc-paginator-navigation-first {
        line-height: 0 !important;
    }

    .confirmation-dialog .mat-mdc-dialog-container {
        margin: 0px !important;
        padding: 0px !important;
    }

    .cancel-button {
        border: 1px solid #2946a1 !important;
        background-color: #fff !important;
        color: #2946a1 !important;
        box-shadow: none;
    }

    .mat-button-toggle .mat-pseudo-checkbox {
        display: none;
    }

    .apploadercontainer {
        overflow: hidden;

        .mat-mdc-dialog-container {
            background: transparent;
            box-shadow: none;
            overflow: hidden;
            text-align: center;

            app-app-loader {
                position: absolute;
            }

            .hrms-loader {
                position: fixed;
                z-index: 999;
                height: 2em;
                width: 2em;
                overflow: visible;
                margin: auto;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }

            .hrms-loader:before {
                content: '';
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
            }
        }
    }

    .no-background {
        background: none !important;
    }

    .location-tabs {

        .mat-mdc-tab-link,
        .mat-mdc-tab-label,
        .mat-mdc-tab-label-active {
            padding: 0px;
            width: 50% !important;
            min-width: 0px !important;
            opacity: 1 !important
        }

        .mat-mdc-tab-label-content {
            font-weight: bold;
            text-transform: uppercase;
            color: #474a4f
        }

        .mat-mdc-tab-label-active {
            .mat-mdc-tab-label-content {
                color: #41a541 !important;
            }
        }

        .mat-ink-bar {
            background-color: #41a541 !important;
        }
    }

    .m-r-10 {
        margin-right: 10px !important;
    }

    .m-t-5 {
        margin-top: 5px !important;
    }

    .m-b-5 {
        margin-bottom: 5px !important;
    }

    .m-t-10 {
        margin-top: 10px !important;
    }

    .p-t-10 {
        padding-top: 10px !important;
    }

    .p-b-10 {
        padding-bottom: 10px;
    }

    .m-b-10 {
        margin-bottom: 10px !important;
    }

    .m-b-0 {
        margin-bottom: 0px !important;
    }

    .m-l-10 {
        margin-left: 10px !important;
    }

    .mat-mdc-card-header-text {
        width: 100% !important;

    }

    .no-card-header-text {
        .mat-mdc-card-header-text {
            display: none !important;
        }
    }

    .m-l-5 {
        margin-left: 5px;
    }

    .m-tb-5 {
        margin: 5px 0px;
    }

    .m-10 {
        margin: 10px !important;
    }

    .m-r-5 {
        margin-right: 5px !important;
    }

    .p-0 {
        padding: 0px !important;
    }

    .m-0 {
        margin: 0px !important;
    }

    .cursor {
        cursor: pointer;
    }

    .p-20 {
        padding: 20px;
    }

    .p-10 {
        padding: 10px !important;
    }

    .mb-2 {
        margin-bottom: 2px;
    }

    .mt-2 {
        margin-top: 2px;
    }

    .font-14 {
        font-size: 14px;
        font-weight: 500;
    }

    .projects-map {
        .mat-expansion-panel-header {
            border-bottom: 1px solid #cdd5de;
            background: #f0f0f0;

            .mat-expansion-panel-header-title {
                color: #414d6a !important;
            }

            .mat-expansion-indicator::after {
                color: #414d6a !important;
            }
        }

        .mat-expansion-panel-content {
            padding: 0px !important;
        }

        .mat-expansion-panel-body {
            padding: 0px !important;
            background: #fff;
        }
    }

    .height-700 {
        height: 700px;
    }

    .full-height {
        height: 100%;
    }

    .display-table {
        display: table !important;
        height: 100% !important;
    }

    .display-table-cell {
        display: table-cell !important;
    }

    .footer-buttons {
        .mat-mdc-button {
            min-width: 90px;
            max-width: 100%;
            padding: 6px 17px;
            border-radius: 4px;
            font-family: proxima-nova, Avenir, sans-serif;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            vertical-align: middle;
            white-space: nowrap;
            letter-spacing: .03em;
            // text-transform: lowercase;
        }

        .cancel-button {
            border: 1px solid #d64545 !important;
            background-color: initial !important;
            color: #d64545 !important;
        }

        .create-button {
            border-color: #41a541 !important;
            background-color: #41a541 !important;
            color: #fff !important;
        }
    }

    .select-prods {
        .mat-pseudo-checkbox-checked {
            background: #41a541;
        }
    }

    .mat-select-search-input {
        color: #000 !important;
    }

    .material-table {
        overflow: auto;
        overflow-x: hidden;
        position: relative;
        width: 100%;
        display: flex;

        .material-custom-table {
            border: 1px solid #bdbdbd;
            background: none;
            overflow-x: hidden !important;
            width: 99.5%;
            margin: 10px 0px;


            .mat-mdc-header-row {
                background: #5d656d;
                color: #fff;

                .mat-checkbox-frame {
                    border-color: #fff !important;
                }

                .mat-checkbox-checked.mat-accent .mat-checkbox-background {
                    background-color: #fff !important;
                }

                .mat-checkbox-checkmark-path {
                    stroke: #1976d2 !important;
                }
            }

            .mat-sort-header-button {
                text-transform: uppercase !important;
                font-weight: bold !important;
                color: #fff;
            }

            .mat-mdc-row:nth-child(odd) {
                background-color: #f7fafc;
            }
        }

        mat-mdc-header-cell {
            text-transform: uppercase !important;
            font-weight: bold !important;
            color: #fff;
        }

        mat-mdc-header-cell:last-of-type,
        mat-cell:last-of-type {
            border-right: none !important;
        }

        mat-mdc-header-cell,
        mat-cell {
            border-right: 1px solid #bdbdbd;
            padding: 0px 10px !important;
        }

        mat-row {
            align-items: stretch;
        }

        .mat-sort-header-arrow {
            color: #fff;
        }

        ::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: rgb(145, 141, 141);
            border-radius: 5px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: rgb(145, 141, 141);
            border-radius: 5px;
        }
    }

    .ngx-datatable.bootstrap {
        box-shadow: none;
        font-size: 13px;
        border: 1px solid #5d656d;

        .datatable-header {
            height: unset !important;
            background: #5d656d !important;
            color: #fff !important;
            width: 100% !important;

            .datatable-header-cell {
                vertical-align: bottom;
                padding: 0.75rem;
                border-bottom: 1px solid #d1d4d7;

                .datatable-header-cell-label {
                    line-height: 24px;
                }
            }
        }

        datatable-scroller {
            width: 100% !important;
        }

        .datatable-body {
            max-height: 500px;
            overflow-y: auto;
            overflow-x: hidden;

            .datatable-body-row {
                padding: 0.75rem;
                vertical-align: top;
                border-top: 1px solid #d1d4d7;

                // &.datatable-row-odd {
                //   background-color: rgba(0, 0, 0, 0.05);
                // }
                &.active {
                    background-color: #1483ff;
                    color: #FFF;
                }

                .datatable-body-cell {
                    text-align: left;
                    vertical-align: top;
                }
            }
        }

        .datatable-footer {
            color: #424242;
            background: #ededed;
            margin-top: -1px;

            .page-count {
                line-height: 50px;
                height: 50px;
                padding: 0 1.2rem;
                color: #f89422;
            }

            .datatable-pager {
                margin: 0 10px;
                vertical-align: top;

                ul {
                    li {
                        margin: 10px 0px;

                        &:not(.disabled) {

                            &.active,
                            &:hover {
                                a {
                                    background-color: #545454;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }

                a {
                    height: 22px;
                    min-width: 24px;
                    line-height: 22px;
                    padding: 0;
                    border-radius: 3px;
                    margin: 0 3px;
                    text-align: center;
                    vertical-align: top;
                    text-decoration: none;
                    vertical-align: bottom;
                    color: #ededed;
                    cursor: pointer;
                }

                .datatable-icon-left,
                .datatable-icon-skip,
                .datatable-icon-right,
                .datatable-icon-prev {
                    font-size: 18px;
                    line-height: 27px;
                    padding: 0 3px;
                }
            }
        }

        .datatable-row-detail {
            height: auto !important;
        }

        .empty-row {
            padding: 10px;
        }

        ::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: rgb(145, 141, 141);
            border-radius: 5px;
        }
    }

    .ngx-datatable .datatable-body .datatable-row-detail {
        margin-left: 10px;
    }

    .ngx-datatable.material-row-details {
        .datatable-body {
            width: 100%;
            // height: 170px !important;
            height: auto !important;
            overflow-x: hidden;
            max-height: 700px;
            overflow-y: auto;
        }

        .datatable-header {
            height: unset !important;
            background: rgba(0, 0, 0, 0.05) !important;
            color: #5d656d !important;
            width: 100% !important;

            .datatable-header-cell {
                vertical-align: bottom;
                padding: 0.5rem;
                .datatable-header-cell-label {
                    line-height: 24px;
                }
            }
        }

        datatable-scroller {
            width: 100% !important;
        }

        .datatable-body {
            .datatable-body-row {
                padding: 0.75rem;
                vertical-align: top;
                border-top: 1px solid #abadaf;

                .datatable-body-cell {
                    text-align: left;
                    vertical-align: top;
                }
            }
        }

        ::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: rgb(145, 141, 141);
            border-radius: 5px;
        }
    }

    .custom-search {
        .mat-mdc-form-field-label {
            padding: 0px 15px;
        }
    }

    .multi-dialog {
        .mat-mdc-card-header-text {
            display: none !important;
            margin: 0px !important;
        }
    }

    .default-panel {
        margin-bottom: 15px !important;

        .mat-expansion-panel-header {
            background: rgba(0, 0, 0, 0.04) !important;
        }
    }

    .view-card-content-layout {
        position: relative;

        .view-tabs {
            margin: 10px 0px;
            opacity: 1;
        }

        .mat-mdc-tab-body {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 8px;
            margin: 10px 0px;
        }

        .mat-mdc-tab-body-content {
            padding: 10px 15px;
            overflow: hidden;
            height: auto;
        }
    }

    .no-data {
        height: auto;
        padding: 15px 0px !important;
        ;

        img {
            width: 75px;
            height: 75px;
            text-align: center;
        }

        .no-data-text {
            font-size: 14px;
            color: #424242;
            text-align: center;
            font-weight: 700;
        }
    }

    .no-data-row {
        overflow: hidden;
        height: auto !important;
    }

    .property-photo-card {
        border-radius: 6px !important;
        ;
        padding: 0px !important;
        box-shadow: 0 0 50px 0 hsla(0, 0%, 71.8%, .15) !important;
        margin-bottom: 15px !important;
        ;
        background: hsla(0, 0%, 100%, .98) !important;
        ;

        .property-photo-header {
            background: #f7fafc !important;
            color: black;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 15px 10px;
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
        }

        .property-photo-content {
            z-index: 21;
            display: block;

            .property-image {
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: cover;
                position: relative;
                display: block;
                overflow: hidden;
                background-color: transparent !important;
                height: 200px;

                img {
                    width: 100%;
                    object-fit: cover;
                    //height: 100%;
                }
            }

            .upload::after {
                content: "";
                position: absolute;
                opacity: 0;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, .5);
                transition: opacity .35s ease;
            }

            .upload {
                color: #ffffff !important;
                padding: 20px;
                display: flex;
                text-align: center;
                justify-content: flex-end;
                cursor: pointer;
                background: transparent linear-gradient(180deg, transparent, rgba(0, 0, 0, .8) 75%) repeat scroll 0 0;

                .upload-sec {
                    margin-left: auto;
                }

                p {
                    margin: 2px !important;
                }

                .upload-sec:hover {
                    color: #41a541 !important;
                }
            }
        }
    }

    .property-photo-content:hover {
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        position: relative;
        display: block;
        overflow: hidden;
        background-color: rgba(0, 0, 0, .5) !important;
        transition: opacity 2.35s ease;
    }

    .pending_req_modal {
        .mat-mdc-dialog-container {
            padding: 0px;
            overflow: hidden;
            max-height: 650px;

            .mat-mdc-card-header {
                background: #2946a1;
                color: #fff;
                padding: 10px 15px;
                height: 40px
            }

            .mat-mdc-card-content {
                padding: 10px 15px;
                margin-bottom: 0px;
                overflow: auto;
                display: block;
            }

            .mat-mdc-card-actions {
                margin: 0 !important;
                padding: 0px !important;
            }
        }
    }

    .MaterialRef-list {
        height: 500px;

        .mat-mdc-dialog-container {
            padding: 0px;
            overflow: hidden;
            height: 500px;

            .mat-mdc-card-header {
                background: #2946a1;
                color: #fff;
                padding: 10px 15px;
                height: 40px
            }

            .mat-mdc-card-content {
                padding: 10px 15px;
                margin-bottom: 0px;
                height: 350px;
                overflow: auto;
                display: block;
            }

            .mat-mdc-card-actions {
                margin: 0 !important;
                padding: 0px !important;
            }
        }
    }

    .backround-custom-red {
        background: #8A1538 !important;
        color: #fff !important;
    }

    .font-400 {
        font-weight: 400;
    }

    .sale-graphs {
        canvas {
            height: auto !important;
        }
    }

    .mat-tree {
        background: none !important;
    }

    .mat-mini-fab.mat-primary {
        background: #f89422 !important;
    }

    .form-separator {
        border-bottom: 1px solid #edf0f5;
        margin: 15px 0px;
    }

    .bg-green {
        background-color: #41a541 !important;
    }

    .bg-red {
        background: #8A1538 !important;
    }

    .bg-gray {
        background-color: #f3f6f9 !important;
    }

    .color-gray {
       color: #f3f6f9 !important;
    }

    .bg-blue {
        background-color: $blue !important;
    }

    .bg-pink {
        background-color: $pink;
    }

    .color-green {
        color: #41a541 !important;
    }

    .color-blue {
        color: #198cf0;
    }

    .color-red {
        color: red !important;
    }

    .color-yellow {
        color: #ffa800 !important
    }

    .color-violet {
        color: $violet !important;
    }

    .color-pink {
        color: $pink !important;
    }

    .color-white {
        color: $white !important;
    }

    .bg-pale-white {
        background: $pale-white !important;
    }

    .bg-light-orange {
        background: $light-orange !important;
    }

    .bg-orange {
        background-color: $orange !important;
    }

    .color-orange {
        color: $orange !important;
    }

    .bg-light-blue {
        background: $light-blue !important;
    }

    .color-blue {
        color: $blue !important;
    }

    .bg-light-pink {
        background: $light-pink !important;
    }

    .bg-light-skyblue {
        background: $light-skyblue !important;
    }

    .color-skyblue {
        color: $skyblue !important;
    }

    .bg-violet {
        background: $violet !important;
    }

    .text-dark {
        color: #181c32 !important
    }

    .active {
        color: #41a541;
    }

    .inactive {
        color: #d64545
    }

    .submitted {
        color: $blue;
        background-color: $light-blue;
        border-radius: .42rem;
        padding: 10px;
        width: auto;
        font-size: .9rem;
    }

    .requested {
        color: $orange;
        background-color: $light-orange;
        border-radius: .42rem;
        padding: 10px;
        width: auto;
        font-size: .9rem;
    }

    .completed {
        color: $skyblue;
        background-color: $light-skyblue;
        border-radius: .42rem;
        padding: 10px;
        width: auto;
        font-size: .9rem;
    }

    .text-heading {
        font-weight: 500;
        font-size: 1.275rem;
        margin: 0px;
    }

    .text-muted {
        color: #b5b5c3 !important
    }

    .text-bold {
        font-weight: bold;
    }

    .stocks-list,
    .notification-card {
        .mat-mdc-card-header-text {
            margin: 0px !important;
        }
    }

    .stock-table {
        .mat-mdc-header-row {
            background-color: #f3f6f9;
            border-bottom: 0;
            letter-spacing: 1px;
        }

        .mat-mdc-row {
            border: 0px;

            .mat-mdc-cell {
                color: #181c32 !important;
                font-weight: bold;
            }
        }
    }

    .display-block {
        display: block !important;
    }

    .height-200 {
        height: 200px !important;
    }

    .filter-panel {
        .mat-mdc-dialog-container {
            padding: 0px !important;
            width: 400px !important;
        }

        .mat-mdc-card-header-text {
            display: none;
        }
    }

    .filter-backdrop {
        background: rgba(53, 65, 83, 0.25);
    }

    .font-20 {
        font-size: 20px;
    }

    .ngx-datatable.bootstrap .datatable-header .datatable-header-cell,
    .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
        text-overflow: ellipsis !important;
        white-space: normal !important;
        text-align: center !important;
        vertical-align: middle !important;

    }

    .datatable-body-row,
    .datatable-row-center,
    .datatable-header-cell,
    .datatable-header-inner {
        width: 100% !important;
    }



    .chart-of-accounts-card {
        .mat-mdc-dialog-container {
            padding: 0px;
        }

        .mat-mdc-card-header-text {
            display: none;
        }
    }

    .mat-progress-bar-buffer {
        background-color: #e6ebf1 !important;
    }

    .emp-filter {
        float: right;
        margin-right: 10px;

        .material-icons {
            position: relative;
            top: 12px;
            z-index: 1;
        }

        .mat-mdc-form-field-wrapper {
            width: auto;
            padding: 0;

            .mat-mdc-form-field-flex {
                .mat-mdc-form-field-infix {
                    width: auto;
                    border: 0 !important;
                    padding: 0;

                    mat-select {
                        visibility: hidden;
                    }
                }
            }

            .mat-select-arrow-wrapper {
                visibility: hidden;
            }

            .mat-mdc-form-field-underline {
                display: none;
            }
        }
    }



    // Leave request module
    .leaverequest {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        label {
            margin: 0px
        }

        .pad-bot-50 {
            padding-bottom: 50px !important;
        }

        .mat-container {
            .card-custom {
                background: #fff;
                padding: 10px;
                margin-bottom: 20px;
                box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
                border-radius: 4px;

                .card-header {
                    display: flex;
                    justify-content: space-between;
                    padding: 5px 10px 5px 10px;
                    border-bottom: 1px solid #EBEDF3;
                    margin-bottom: 20px;
                    min-height: 50px;

                    h1 {
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                    }
                }

                .card-body {
                    padding: 0px 10px;

                    p {
                        font-size: 14px;
                        margin-bottom: 5px;
                    }
                }

                .blue-btn {
                    color: #FFFFFF;
                    background-color: #f89422;
                    border-color: #f89422;
                    font-size: 15px;
                    height: 35px;
                    padding: 0px 20px;
                    box-sizing: border-box;
                    border-radius: 5px;
                }

                .status-under_approval {
                    color: #fff;
                    background-color: #bfd1d9;
                    border-radius: 100px !important;
                    padding: 2px 10px;
                }

                .status-medium {
                    color: #fff;
                    background-color: #f89422;
                    border-radius: 100px !important;
                    padding: 2px 10px;
                }

                .status-high {
                    color: #fff;
                    background-color: #fd397a;
                    border-radius: 100px !important;
                    padding: 2px 10px;
                }

                .status-low {
                    color: #fff;
                    background-color: green;
                    border-radius: 100px !important;
                    padding: 2px 10px;
                }

                p {
                    a {
                        color: #2da7e8;
                    }
                }
            }

            .card-timeline {
                position: relative;
                width: 100%;
                display: inline-table;

                &:before {
                    content: "";
                    position: absolute;
                    left: 71px;
                    width: 3px;
                    top: -4px;
                    bottom: 0;
                    background-color: #EBEDF3;
                    left: 75px !important;
                }
            }

            .card-timeline .timeline-item {
                display: flex;
                align-items: center;
                position: relative;
                margin-bottom: 1.7rem;
                width: 65px;
                float: left;
            }

            .align-items-start {
                align-items: flex-start !important;
            }

            .card-timeline .timeline-label {
                flex-shrink: 0;
                font-size: 13px;
                font-weight: 500;
                position: relative;
                color: #3F4254;
                line-height: 25px;
                width: 75px;
            }

            .card-timeline .timeline-badge {
                flex-shrink: 0;
                background: #ffffff;
                width: 16px;
                height: 16px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                position: relative;
                margin-top: 2px;
                margin-left: 3px;
                padding: 3px !important;
                border: 2px solid #f89422 !important;
                float: left;
                margin-right: 21px;
            }

            .timeline-content {
                float: left;
                font-size: 14px;
            }
        }

        .card-footer {
            border-top: 1px solid #EBEDF3;
            margin-top: 20px;
            padding-top: 20px;
            margin-bottom: 20px;

            input {
                width: 87%;
                border: 1px solid #E4E6EF;
                height: 35px;
                border-radius: 5px;
                font-size: 14px;
                padding: 0px 15px;
                box-sizing: border-box;
            }

            button {
                background: #fd397a;
                border: 0px;
                color: #ffffff;
                font-size: 14px;
                height: 35px;
                padding: 0px 20px;
                text-transform: capitalize;
                border-radius: 0px 4px 4px 0px;
            }
        }
    }

    .uploadmodal {
        margin-top: 50px;
    }

    .upload-modal {
        .mar-bot-30 {
            margin-bottom: 30px !important;
        }

        .center {
            ngx-file-drop {
                width: auto;

                .ngx-file-drop__drop-zone {
                    height: auto;
                    border: 0;
                    margin-bottom: 50px;

                    .ngx-file-drop__content {
                        align-items: center;
                        color: #0782d0;
                        display: inline-table;
                        height: auto;
                        width: 100%;

                        h1 {
                            margin: 0px 0px 20px 0px;
                            text-align: center;
                            font-size: 22px;
                            color: #000;
                            border-bottom: 1px solid #9b9b9b;
                            padding-bottom: 15px;
                        }

                        h3 {
                            color: #a5c1d9;
                            margin: 10px 0px 20px 0px;
                        }

                        p {
                            text-align: center;
                            font-size: 16px;
                            color: #000;
                            margin: 0px;
                        }

                        .green-txt {
                            color: #41a640;
                        }

                        .file-upload {
                            background: #eef3f9;
                            padding: 10px;
                            border: 1px dashed #000;
                            text-align: center;

                            mat-icon {
                                color: #a5c1d9;
                                font-size: 70px !important;
                                position: relative;
                                left: -15px;
                            }

                            .custom-file-input::-webkit-file-upload-button {
                                visibility: hidden;
                            }

                            .custom-file-input::before {
                                content: "CHOOSE A FILE";
                                display: inline-block;
                                border: 1px solid #999;
                                border-radius: 3px;
                                padding: 10px 20px;
                                outline: none;
                                -webkit-user-select: none;
                                cursor: pointer;
                                font-weight: 700;
                                font-size: 10pt;
                                background: #41a640;
                                color: #fff;
                            }

                            .custom-file-input:hover::before {
                                border-color: black;
                            }

                            .custom-file-input:active::before {
                                background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
                            }

                            .custom-file-input {
                                margin: 0 auto 60px;
                                display: block;
                                width: 135px;
                            }
                        }
                    }
                }
            }
        }

        .upload-table {
            table {
                border: 1px solid #dedede;
                margin-bottom: 30px;

                tr {
                    th {
                        font-size: 12px;
                        font-weight: 500;
                        background: #edf0f5;
                    }

                    td {
                        font-size: 12px;
                        font-weight: 500;
                        border-bottom: 1px solid #dedede;
                        padding-left: 20px;
                    }
                }
            }

            .upload-btn {
                display: flex;
                justify-content: space-between;

                button {
                    height: 35px;
                    padding: 5px 20px;
                    text-align: center;
                    color: #000;
                    box-sizing: border-box;
                    border: 1px solid #41a640;
                    border-radius: 4px;
                    font-size: 15px;
                }

                .green-btn {
                    background: #41a640;
                    color: #fff;
                }
            }
        }
    }

    @media only screen and (min-width: 1440px) and (max-width:1600px) {
        .mat-mdc-table.employee-listview .mat-mdc-table {
            width: 1215px !important;
        }
    }

    @media only screen and (min-width: 1601px) and (max-width:1920px) {
        .mat-mdc-table.employee-listview .mat-mdc-table {
            width: 1470px !important;
        }
    }

    .unpaid {
        border-radius: 50px;
        padding: 10px;
        background: #f2dcdc;
        color: red;
    }

    .paid {
        border-radius: 50px;
        padding: 10px;
        background: #d3ebd8;
        color: #41a541;
    }

    .custom-invoice-table {

        .date_of_entry {
            color: #414d6a;
        }

        .payment_mode {
            color: #41a541;
        }

        .grand_total {
            color: #414d6a;
        }

        .published {
            color: #41a541;
        }

        .publish {
            background-color: #29A9E9 !important;
            color: #fff !important;
            padding: 0;
            white-space: pre-wrap !important;
            margin: 10px 0px;
        }

        mat-mdc-header-cell {
            text-transform: uppercase !important;
            font-weight: bold !important;
            color: #504c69 !important;
        }

        .mat-mdc-row {
            border: none !important;
            min-height: 60px;
        }

        .mat-mdc-row:nth-child(odd) {
            background-color: #f0f3f8;
        }
    }

    .right-title {
        color: #69519E;
        font-size: 16px;
        margin: 10px 0px;

    }

    .ht-500 {
        max-height: 500px;
        overflow-y: auto
    }

    .font-bold {
        font-weight: bold !important;
        text-transform: capitalize !important;
    }

    .text-dark {
        color: #414d6a !important;
    }

    .text-info {
        color: #3F4254 !important;
    }

    .unit-info {
        color: #41a541 !important;
    }

    .inv_amount {
        font-weight: bolder;
        font-size: 20px;
        color: #322A7D;
    }

    .m-t-20 {
        margin-top: 20px !important;
    }

    .font-50 {
        font-size: 50px !important;
    }

    .font-30 {
        font-size: 30px !important;
    }

    .text-tenant {
        color: #322A7D !important;
    }

    .text-light {
        color: #999 !important;
        font-weight: 600;
    }

    .right-detail-card {
        background: #3d4752;
        color: #fff !important;
    }

    .right-custom-card {
        background: #3d4752 !important;

    }

    .vl {
        border-left: 2px solid #fff;
    }

    .text-white {
        color: #fff;
    }

    .req {
        border-radius: 50px;
        padding: 10px;
        background: #bed8f1;
        color: #1976d2;
    }

    .com {
        border-radius: 50px;
        padding: 10px;
        background: #afe3af;
        color: #41a541;
    }

    .proce {
        border-radius: 50px;
        padding: 10px;
        background: hsl(33, 59%, 81%);
        color: #f89422;
    }

    .post {
        border-radius: 50px;
        padding: 10px;
        background: #dca6b7;
        color: #8A1538
    }

    .approve-button {
        background: #41a541 !important;
        border: 0px;
        color: #ffffff !important;
        font-size: 14px;
        padding: 0px 20px;
        text-transform: capitalize;
        border-radius: 0px 4px 4px 0px;
    }

    .reject-button {
        background: #8A1538 !important;
        border: 0px;
        color: #ffffff !important;
        font-size: 14px;
        padding: 0px 20px;
        text-transform: capitalize;
        border-radius: 0px 4px 4px 0px;
    }

    .edit-button {
        background: #198cf0 !important;
        border: 0px;
        color: #ffffff !important;
        font-size: 14px;
        padding: 0px 20px;
        text-transform: capitalize;
        border-radius: 0px 4px 4px 0px;
    }

    .chip-green {
        background: #41a541 !important;
        color: #fff !important;
    }

    .ht-150 {
        height: 150px;
    }

    .bg-custom-blue {
        background: #1878f3 !important;
        color: #fff !important;
    }

    .bg-custom-pink {
        background: #f0294a !important;
        color: #fff !important;
    }

    .bg-custom-violet {
        background: #9260f7 !important;
        color: #fff !important;
    }

    .bg-custom-sea {
        background: #29bba6 !important;
        color: #fff !important;
    }

    .circle-progress-wrapper .custom-circle-progress svg {
        display: block;
        width: 45px !important;
        height: 45px !important;
    }

    .top-4 {
        position: relative;
        top: 4px;
    }

    .mat-mdc-chip-action-label {
        color: inherit;
    }

    .details-chips {
        margin-bottom: 10px;

        mat-chip {
            background: #fff !important;
            color: #0b0a0a !important;
            border: 2px solid green !important;

            mat-icon {
                color: red !important;
            }
        }
    }

    .hidden-file {
        display: none;
    }

    .chq_img {
        width: 32px;
        height: 32px;
        cursor: pointer;
        border-radius: 50%;
        object-fit: contain;
        border: 1px solid silver;
    }

    .progress-bar-pink {
        .mat-progress-bar-fill::after {
            background-color: #f64e60 !important;
        }
    }

    .progress-bar-green {
        .mat-progress-bar-fill::after {
            background-color: #41a541 !important;
        }
    }

    .progress-bar-orange {
        .mat-progress-bar-fill::after {
            background-color: $orange !important;
        }
    }

    .progress-bar-blue {
        .mat-progress-bar-fill::after {
            background-color: $blue !important;
        }
    }

    .custom-bg-primary {
        background-color: #274298 !important;
        color: #fff !important;
    }

    .text-end {
        text-align: center;
    }

    .text-center {
        text-align: center !important;
    }


    .example-detail-row {
        min-height: 0 !important;
        width: 100%;
    }

    .source-evalution {
        .mat-mdc-dialog-container {
            padding: 0px !important;
            overflow: hidden !important;
        }

        .dialog-title {
            color: #fff !important;
            background-color:#a7afdd !important;
            padding: 5px !important;
            h4 {
                margin: 0px !important;
            }
        }
        .mat-mdc-dialog-content {
            margin: 0px !important;
            padding: 20px !important;
            overflow-x: hidden !important;
        }
        .mat-mdc-dialog-actions {
            padding: 10px 20px !important;
            min-height: auto !important;
            justify-content: space-between !important;
            align-items: center !important;
            margin-bottom: 0px !important;
        }
    }
