@import '../styles/var';
.card {
  background: white;
  border-radius: 2px;

  .header {
    min-height: 64px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid #e0e0e0;
    font-size: 20px;

    .title {
      .extra {
        font-size: 12px;
        color: #888;
      }
    }
  }

  .footer {
    padding: 8px 16px;
    border-top: 1px solid #E0E0E0;

    button + button {
      margin-left: 8px;
    }
  }

  &.border {
    border: 1px solid #E0E0E0;
  }

  &.space {

    .header {
      @include responsivePadding(left);
      @include responsivePaddingHalf(right);
    }

    .content {
      @include responsivePadding();
    }

    .footer {
      padding: 8px;
      @include responsivePadding(left);
    }
  }

  &.no-padding-content {
    .content {
      padding: 0;
    }
  }

  &.alternative {
    background: white;
    overflow: hidden;
    @include responsivePadding();
    @include cardBorder;

    &.no-border {
      border: none;
    }

    .header {
      min-height: inherit;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      border-bottom: none;

      .title {
        font-size: 18px;
      }

      .sub-title {
        font-size: 14px;
        color: $color__text-light;
      }
    }
  }
}
